<template>
  <div class="container-xl d-flex flex-column justify-content-center">
    <kinesis-container>
              <kinesis-element :strength="10">
                <center>
                  <img
            src="@/assets/static/logo.png"
            width="150"
            height="70"
            style="filter: brightness(0) invert(1);"
          />
                  <img
            src="@/assets/static/scum/scum-logo.png"
            width="150"
            height="60"
          />
          <img
            src="@/assets/static/scum/gamepires_white.png"
            width="150"
            height="120"

          />
          </center>
              </kinesis-element>
              <kinesis-element :strength="20">
                <center><h1>{{ $t('homePage.text1') }}</h1>
                <h1>{{ $t('homePage.text2') }}</h1>
                <h1>{{ $t('homePage.text3') }} <svg xmlns="http://www.w3.org/2000/svg" class="icon text-pink icon-filled icon-inline" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" /></svg></h1>
                </center>
              </kinesis-element>
              <kinesis-element :strength="30">
                <center><img
            src="@/assets/static/scum/scum_prisoner.png"
            width="550"
            height="500"
          /></center>
              </kinesis-element>
          </kinesis-container>
  </div>
</template>

<script>
export default {
    name: "MainPage",
    data: function () {
      return {
        
      }
    },
    methods: {

    },
    created() {
      
    }
}
</script>